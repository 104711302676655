import HyperFormula from "hyperformula";
import server from "../../controllers/server";
import { Logger } from "../logger/logger";
import { CONFIG } from "./config";
import Store from "../../store";
import {
  containsRussian,
  hasCrossRef,
  hasFormula,
  removeQuotesFromWords,
  wrapSheetsWithQuotes,
} from "../HyperformulaUtils";
import { LSCell, LuckysheetWindow } from "./types";
import { convertCellDataToManagerData } from "../HyperformulaUtils";

declare const window: LuckysheetWindow;

export class HyperLuckysheet {
  static instance: HyperLuckysheet;
  manager: HyperFormula;

  constructor() {
    if (HyperLuckysheet.instance) {
      return HyperLuckysheet.instance;
    }

    HyperLuckysheet.instance = this;
    window.computationEngine = this;
    Logger.info("Используется: HyperLuckysheet", "ENGINE");
  }

  buildAllSheets(sheets: any[]) {
    const russianSheets = sheets.filter(sheet => containsRussian(sheet.name)).map(sh => sh.name);
    const preparedData = sheets.reduce((acc, currentSheet) => {
      const { name, celldata } = currentSheet;

      return {
        ...acc,
        [name]: convertCellDataToManagerData(celldata, russianSheets),
      };
    }, {});

    this.manager = HyperFormula.buildFromSheets(preparedData, CONFIG);
  }

  getCellValue(formulaValue: string, sheetLSIndex: string) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);
    let formula = formulaValue;
    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (hasCrossRef(formulaValue)) {
      const allSheets = this.manager.getSheetNames();
      formula = wrapSheetsWithQuotes(formula, allSheets);
    }

    if (managerSheetId !== undefined && hasFormula(formula)) {
      const formulaResult = this.manager.calculateFormula(formula, managerSheetId);
      return formulaResult;
    } else {
      return formulaValue;
    }
  }
  pasteContent(topLeft: { row: number; col: number }, contentData: string | number[][], sheetLSIndex: string) {
    const { row, col } = topLeft;
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const pasteAddress = { row, col, sheet: managerSheetId };
      this.manager.setCellContents(pasteAddress, contentData);
    }
  }

  updateCell(row: number, col: number, cellLS: LSCell, sheetLSIndex: string) {
    const { v: cellValue, f: formulaRaw } = cellLS || {};

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      const allSheets = this.manager.getSheetNames();
      formula = wrapSheetsWithQuotes(formula, allSheets);
    }

    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const value = formula || cellValue;
      const cellAddress = { col, row, sheet: managerSheetId };
      const currentFormula = this.manager.getCellFormula(cellAddress);
      const existFormula = this.manager.doesCellHaveFormula(cellAddress);
      const currentValue = this.manager.getCellValue(cellAddress);

      const isSameFormula = existFormula && currentFormula === formula;
      const isSameValue = currentValue && currentValue === cellValue;

      if (isSameValue || isSameFormula) {
        return;
      }

      this.manager.setCellContents(cellAddress, value);

      const hasFormula = this.manager.doesCellHaveFormula(cellAddress);
      const formulaCell = this.manager.getCellFormula(cellAddress);

      Logger.info(
        `[${row}, ${col}]: ${hasFormula ? formulaCell : ""} ${cellValue ? cellValue : ""}`,
        `UPDATE CELL IN ${sheetNameRaw}`
      );
    }
  }

  clearContent(start: { row: number; col: number }, end: { row: number; col: number }, sheetLSIndex: string) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const { row: startRow, col: startCol } = start;
      const { row: endRow, col: endCol } = end;
      const rowsLength = endRow - startRow + 1;
      const colsLength = endCol - startCol + 1;

      const clearArea = Array(rowsLength)
        .fill([])
        .map(arr => Array(colsLength).fill(undefined));
      const topLeftAddress = {
        row: startRow,
        col: startCol,
        sheet: managerSheetId,
      };

      this.manager.setCellContents(topLeftAddress, clearArea);
    }
  }

  createNewSheet(sheetName: string) {
    this.manager.addSheet(`${sheetName}`);
  }

  updateSheetData(data: string[][], sheetId: number) {
    this.manager.setSheetContent(sheetId, data);
  }

  renameSheet(oldSheetName: string, newSheetName: string) {
    const sheetId = this.manager.getSheetId(oldSheetName);

    if (sheetId !== undefined) {
      this.manager.renameSheet(sheetId, `${newSheetName}`);
      this.viewRawSheet(sheetId, newSheetName, `RENAME ${oldSheetName} ->`);
      this.updateDependentFormulas(sheetId);
    }
  }

  addColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.addColumns(managerSheetId, [indexCol, countColumns]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  addRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.addRows(managerSheetId, [indexRow, countRows]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  removeColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.removeColumns(managerSheetId, [indexCol, countColumns]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  removeRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      this.manager.removeRows(managerSheetId, [indexRow, countRows]);
      this.updateDependentFormulas(managerSheetId);
    }
  }

  public viewAllRawSheets() {
    const allRawSheets = HyperLuckysheet.instance.manager.getAllSheetsSerialized();
    Object.entries(allRawSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA RAW VALUES", listName);
    });
  }

  public viewAllSheetsValues() {
    const allValuesSheets = HyperLuckysheet.instance.manager.getAllSheetsValues();
    Object.entries(allValuesSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA VALUES", listName);
    });
  }

  private viewRawSheet(sheetId: number, sheetNameRaw: string, action: string) {
    Logger.table(this.manager.getSheetSerialized(sheetId), action, sheetNameRaw);
  }

  private getSheetLS(sheetIndex?: string) {
    return window.luckysheet.sheetmanage.getSheetName(sheetIndex);
  }

  /**
   * Обновляет все формулы, которые зависят от текущего листа
   * Также обновляет состояние LS
   * @param sheetId - айди листа, от которого зависят другие листы
   */
  private updateDependentFormulas(sheetId: number) {
    const cellsForUpdating: any = [];

    const renamedSheet = this.manager.getSheetName(sheetId);
    const allSheets = this.manager.getSheetNames().filter(sheetName => sheetName !== renamedSheet);

    const russianSheets = this.manager.getSheetNames().filter(containsRussian);

    allSheets.forEach(sheetName => {
      const currentId = this.manager.getSheetId(sheetName);

      if (currentId !== undefined && renamedSheet) {
        const sheetFormulas = this.manager.getSheetFormulas(currentId);
        const currentFile = Store.luckysheetfile.find(shs => shs.name === sheetName);

        const currentLSData = currentFile.data;
        const currentSheetIndex = currentFile.index;

        sheetFormulas.forEach((rowItem, row) => {
          rowItem.forEach((updatedFormula, col) => {
            if (currentLSData && updatedFormula && updatedFormula.includes(renamedSheet)) {
              currentLSData[row][col] = {
                ...currentLSData[row][col],
                f: removeQuotesFromWords(updatedFormula, russianSheets),
              };

              cellsForUpdating.push({
                ...currentLSData[row][col],
                f: removeQuotesFromWords(updatedFormula, russianSheets),
                address: {
                  r: row,
                  c: col,
                  i: currentSheetIndex,
                },
              });
            }
          });
        });
      }
    });

    if (cellsForUpdating.length > 0) {
      server.saveParam("gv", 0, cellsForUpdating);
    }
  }
}
