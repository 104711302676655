import { Logger } from "../../logger/logger";

export class BufferController {
  queue: any = [];
  sliceSize = 300;

  constructor() {
    Logger.info("Инициализирован DataBuffer", "INITIALIZE");

    this.queue = [];
  }

  add(data: any) {
    Logger.info(data, "ADD QUEUE");
    this.queue.push(...data);
  }

  getPartData() {
    // console.log(this.queue, this.queue.splice(0, this.sliceSize));
    return {
      cells: this.queue.splice(0, this.sliceSize),
      partWaiting: this.getQuantityWaited(),
    };
  }

  getQuantityWaited() {
    return Math.round(this.queue.length / this.sliceSize);
  }

  clear() {
    Logger.info("Очередь очищена", "CLEAR QUEUE");

    this.queue = [];
  }
}
