import { CellValue, DetailedCellError, ExportedCellChange, HyperFormula, RawCellContent } from "hyperformula";
import { CONFIG, SPILL_FORMULAS } from "../Hypersheet/config";
import { Area, LSCelldataType, UpdateInput } from "../Hypersheet/types";
import { update } from "../../global/format";
import server from "../../controllers/server";
import { getSheetIndexByName } from "../../methods/get";

export function convertCellDataToManagerData(celldata, allSheets: string[]) {
  let newValuesArrs: any[][] = [[]];

  celldata?.forEach((cell: LSCelldataType) => {
    const { r: row, c: column, v } = cell || {};
    const { v: originalCellValue, f: formulaRaw } = v || {};

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      formula = wrapSheetsWithQuotes(formula, allSheets);
    }

    if (!newValuesArrs[row]) {
      newValuesArrs[row] = [];
    }
    newValuesArrs[row][column] = formula ? formula : originalCellValue;
  });

  const rowsLength = newValuesArrs.length;

  for (let ii = 0; ii < rowsLength; ii++) {
    if (!newValuesArrs[ii]) {
      newValuesArrs[ii] = new Array(rowsLength).fill(undefined);
    }
  }

  return newValuesArrs;
}

export function containsRussian(name) {
  return /[А-Яа-яЁё]/.test(name);
}

export function removeSingleQuotes(input: string) {
  return input.replace(/'/g, "");
}

export function hasCrossRef(input: string) {
  return input.includes("!");
}

export function wrapSheetsWithQuotes(inputString: string, allSheets: string[]): string {
  const russianSheets = allSheets.filter(sheet => containsRussian(sheet));
  const nonRussianSheets = allSheets.filter(sheet => !containsRussian(sheet));
  const sheetsWithUnderscore = allSheets.filter(sheet => sheet.includes("_"));

  let result = inputString;

  // Оборачиваем русские листы в кавычки, если не обёрнуты
  if (russianSheets.length > 0) {
    const russianRegex = new RegExp(`(?<!')(${russianSheets.join("|")})(?!')`, "g");
    result = result.replace(russianRegex, "'$1'");
  }

  // Удаляем кавычки вокруг англ. листов
  if (nonRussianSheets.length > 0) {
    const nonRussianRegex = new RegExp(`'(?<!')(${nonRussianSheets.join("|")})(?!')'`, "g");
    result = result.replace(nonRussianRegex, "$1");
  }

  // Оборачиваем в кавычки все листы с нижним подчеркиванием (если не обёрнуты)
  if (sheetsWithUnderscore.length > 0) {
    const underscoreRegex = new RegExp(`(?<!')(${sheetsWithUnderscore.join("|")})(?!')`, "g");
    result = result.replace(underscoreRegex, "'$1'");
  }

  return result;
}

export function removeQuotesFromWords(inputString: string, words: string[]): string {
  if (words.length === 0) {
    return inputString;
  }
  // Создаем регулярное выражение, чтобы находить слова, обернутые в кавычки
  const regex = new RegExp(`'(${words.join("|")})'`, "g");

  // Заменяем найденные слова без кавычек
  return inputString.replace(regex, "$1");
}

/**
 * Проверяет наличие слова "TRANSPOSE" в строке (независимо от регистра)
 * @param input - Строка, в которой выполняется поиск
 * @returns boolean - true, если строка содержит "TRANSPOSE", иначе false
 */
export function containsSpillFormula(input?: string | number): boolean {
  if (typeof input === "number") return false;
  return SPILL_FORMULAS.some(spillFormula => input?.toUpperCase().includes(spillFormula));
}

export function findMaxRC(data) {
  let maxR = 0;
  let maxC = 0;

  if (!data || data.length === 0) {
    return {
      maxC: 0,
      maxR: 0,
    };
  }

  data.forEach(({ r, c }) => {
    if (r > maxR) maxR = r;
    if (c > maxC) maxC = c;
  });

  return { maxR, maxC };
}

export function flattenMatrix(valueMatrix: CellValue[][], monitorMatrix: RawCellContent[][]) {
  return valueMatrix.flatMap((row, r) => row.map((value, c) => ({ r, c, m: monitorMatrix[r][c], v: value })));
}

export function filterUniqueAddresses(data: any[]) {
  const seen = new Set();

  return data.filter(({ address }) => {
    const key = `${address.r},${address.c},${address.i}`;
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });
}

export function isInArea(row: number, col: number, area: Area): boolean {
  const { colEnd, colStart, rowEnd, rowStart } = area;

  return row <= rowEnd && row >= rowStart && col >= colStart && col <= colEnd;
}

function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

export function getMonitorValue(cell: LSCelldataType["v"], newFormat?) {
  const cellValue = cell ? cell.v : null;

  const format = newFormat || cell?.ct;

  if (isObject(cellValue)) {
    return cellValue?.["value"] || "";
  }

  if (cellValue && format) {
    return update(format?.fa, cellValue);
  }

  return cell?.v;
}

export function hasFormula(value: string) {
  return typeof value === "string" && value.startsWith("=");
}

export function printExcelLikeTable(matrix: any[][]): void {
  try {
    const table: { [key: string]: any }[] = [];

    // Количество строк и столбцов в массиве
    const rows = matrix?.length;

    let maxColumns = matrix[0].length;
    for (let r = 0; r < rows; r++) {
      if (matrix[r].length > maxColumns) {
        maxColumns = matrix[r].length;
      }
    }

    // Генерация таблицы с буквами как заголовками колонок
    for (let row = 0; row < rows; row++) {
      const rowData: { [key: string]: any } = { "#": row + 1 }; // Нумерация строк

      for (let col = 0; col < maxColumns; col++) {
        const columnLetter = String.fromCharCode(65 + col); // A, B, C...
        rowData[columnLetter] = matrix[row][col] || undefined; // Значение из двумерного массива
      }

      table.push(rowData);
    }

    console.table(table);
  } catch (error) {
    console.error("Ошибка в создании матрицы");
  }
}

export function headlessUpdateFromInputs(inputData: UpdateInput) {
  // @ts-ignore
  const { gridKey, loadSheetUrl, loadUrl } = luckysheet.server;
  // @ts-ignore
  const jq = window.$;
  jq.post(loadUrl, { gridKey: gridKey }, data => {
    const baseModel = JSON.parse(data);
    const sheetindexes = baseModel.map(shs => shs.index);

    jq.post(loadSheetUrl, { gridKey: gridKey, index: sheetindexes.join(",") }, onlyCelldataRaw => {
      let fullModel = baseModel;
      const onlyCelldata = JSON.parse(onlyCelldataRaw);

      Object.entries(onlyCelldata).forEach(([sheetIndex, celldata]) => {
        const currentSheet = fullModel.find(sheet => sheet.index === sheetIndex);
        if (currentSheet) {
          currentSheet.celldata = celldata;
        }
      });

      const russianSheets = fullModel.filter(sheet => containsRussian(sheet.name)).map(sh => sh.name);
      const preparedData = fullModel.reduce((acc, currentSheet) => {
        const { name, celldata } = currentSheet;

        return {
          ...acc,
          [name]: convertCellDataToManagerData(celldata, russianSheets),
        };
      }, {});

      const hf = HyperFormula.buildFromSheets(preparedData, CONFIG);

      Object.entries(inputData).forEach(([sheetIndex, value]) => {
        const currentSheet = fullModel.find(sheet => sheet.index === sheetIndex);
        const managerSheetId = hf.getSheetId(currentSheet.name);

        if (managerSheetId !== undefined) {
          const changedCells = hf.setCellContents({ row: 0, col: 0, sheet: managerSheetId }, value);
          const prepairedCells = changedCells.map(HF_cell => {
            if (HF_cell instanceof ExportedCellChange) {
              const { address: HF_address, newValue } = HF_cell;
              const { row: HF_row, col: HF_col } = HF_address;

              const HF_sheetName = hf.getSheetName(HF_address.sheet);
              const LS_sheetIndex = getSheetIndexByName(HF_sheetName);

              let LS_currentCell = currentSheet.celldata.find(curCell => curCell.r === HF_row && curCell.c === HF_col);

              const LS_currentIndexCell = currentSheet.celldata.findIndex(
                curCell => curCell.r === HF_row && curCell.c === HF_col
              );
              const isNewCell = LS_currentIndexCell === -1;

              if (isNewCell) {
                console.error("Не найдена ячека:", { row: HF_row, col: HF_col, name: currentSheet.name });
                return;
              }

              const monitorDefaultValue = newValue !== null ? newValue.toString() : "";
              const isErrorValue = newValue instanceof DetailedCellError;

              const monitorConvertedValue = LS_currentCell
                ? getMonitorValue({
                    ...LS_currentCell?.v,
                    m: monitorDefaultValue,
                    v: newValue,
                  })
                : null;
              const isExistFormula = hf.doesCellHaveFormula(HF_address);
              const HF_formula = hf.getCellFormula(HF_address);

              return {
                ...LS_currentCell?.v,
                ...(isExistFormula &&
                  HF_formula && {
                    f: removeQuotesFromWords(HF_formula, russianSheets),
                  }),
                m: monitorConvertedValue ? monitorConvertedValue : monitorDefaultValue,
                v: isErrorValue ? newValue.value : newValue,
                ct: LS_currentCell?.v?.ct ? LS_currentCell?.v?.ct : { fa: "General", t: "g" },
                address: {
                  r: HF_row,
                  c: HF_col,
                  i: LS_sheetIndex,
                },
              };
            }
          });
          if (prepairedCells.length > 0) {
            server.saveParam("gv", undefined, filterUniqueAddresses(prepairedCells));
          }
        }
      });
    });
  });
}
