import en from "./en";
import ru from "./ru";
import zh from "./zh";
import es from "./es";
import zh_tw from "./zh_tw";
import Store from "../store";

export const localesList = ["en", "zh", "es", "zh_tw", "ru"];
export const locales = { en, zh, es, zh_tw, ru };

function locale() {
  return locales[Store.lang];
}

export default locale;
