import { Logger } from "../../logger/logger";
import { BufferController } from "./BufferController";
import server from "../../../controllers/server";
import { v4 as uuidv4 } from "uuid";
import { LuckysheetWindow } from "../types";
import { filterUniqueAddresses } from "../../HyperformulaUtils";

declare const window: LuckysheetWindow;

// ✅ Очистка таймера при перезагрузке страницы
window.addEventListener("beforeunload", () => {
  SenderController.stop();
  console.log("✅ Таймер очищен перед перезагрузкой страницы.");
});

export class SenderController {
  static timerId: number | null = null;
  static remainingParts: string[] = [];
  private interval: number;

  constructor(private buffer: BufferController, interval: number = 500) {
    console.log("⚠️ Создан новый SenderController");

    // ✅ Очищаем старый таймер перед созданием нового экземпляра
    SenderController.stop();

    this.buffer = buffer;
    this.interval = interval;
    this.start();
  }

  /** ✅ Статический метод остановки таймера */
  static stop() {
    if (SenderController.timerId) {
      clearInterval(SenderController.timerId);
      SenderController.timerId = null;
      console.log("✅ Таймер успешно очищен.");
    }
  }

  /** ✅ Запуск `setInterval` (очищает старый перед запуском нового) */
  private start() {
    Logger.info("Инициализирован SenderController", "INITIALIZE");

    if (SenderController.timerId) {
      console.warn("❌ Таймер уже запущен! Очистка...");
      SenderController.stop();
    }

    SenderController.timerId = window.setInterval(
      () => this.sendData(),
      this.interval
    );
    console.log(`✅ Новый таймер запущен (интервал: ${this.interval} мс)`);
  }

  /** ✅ Отправка данных */
  async sendData() {
    const { cells, partWaiting } = this.buffer.getPartData();

    if (window?.EventBus) {
      window.EventBus.emit("partsInfo", {
        remaining: SenderController.getQuantityRemaining(),
        waiting: partWaiting,
      });
    }

    if (cells.length > 0) {
      const filteredData = filterUniqueAddresses(cells);
      const guid = uuidv4();
      SenderController.remainingParts.push(guid);
      Logger.info("Отправка данных...", "SEND");
      Logger.info(cells, "SENDING DATA");
      server.saveParam("gv", undefined, filteredData, undefined, guid);
    }
  }

  static getQuantityRemaining() {
    return SenderController.remainingParts.length;
  }

  static completePart(guid: string) {
    SenderController.remainingParts = SenderController.remainingParts.filter(
      item => item !== guid
    );
  }
}

// ✅ Глобальная проверка, чтобы не создавать дубликаты
//@ts-ignore
// if (!window.senderController) {
//   //@ts-ignore
//   window.senderController = new SenderController(new BufferController());
// }
