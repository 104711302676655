import { CellValue, SimpleCellAddress } from "hyperformula";
import { Hypersheet } from "../index";
import { containsSpillFormula } from "../../HyperformulaUtils";

type LockSpillAddressType = Record<number, Set<string>> | {};
type LockSpillFormula = Record<number, Map<string, Set<string>>>;

export class SpillManager {
  lockSpillCells: LockSpillAddressType = {};
  spillAddress: LockSpillFormula = {};

  constructor(private hypersheet: Hypersheet) {}

  createSpillCell(row: number, col: number, sheetId: number, formula: string) {
    const spillResult = this.hypersheet.manager.calculateFormula(
      formula,
      sheetId
    ) as CellValue[][];
    let [cellsLocked, spillFormulas] = this.getAddressLockedCells(sheetId);
    const tempCollection = new Set<string>();
    const height = spillResult.length;
    const width = spillResult[0]?.length || 0;

    for (let i = 0; i < height; i++) {
      for (let j = 0; j < width; j++) {
        tempCollection.add(`${row + i}:${col + j}`);
      }
    }
    // Удаление адреса самой ячейки, так как эту ячейку необходимо отправить на сохранение
    tempCollection.delete(`${row}:${col}`);
    spillFormulas.set(`${row}:${col}`, tempCollection);

    this.lockSpillCells[sheetId] = new Set<string>([
      ...cellsLocked,
      ...tempCollection,
    ]);

    return cellsLocked;
  }

  processSpilledFormula(
    row: number,
    col: number,
    sheetId: number,
    cellsUpdated?: any,
    formula?: string
  ) {
    if (formula && this.checkSpilledFormula(formula)) {
      const [, spillFormulas] = this.getAddressLockedCells(sheetId);

      // Если мы редактируем разливающуюся формулу, то перед этим обнулим ее забл. ячейки
      if (spillFormulas.has(`${row}:${col}`)) {
        this.removeSpillAndDependents({ row, col, sheet: sheetId });
      }

      this.createSpillCell(row, col, sheetId, formula);

      return this.filterSpillCells(cellsUpdated, sheetId);
    }

    return cellsUpdated;
  }

  fillLockSpilledCells(sheetId: number) {
    const sheetFormulas = this.hypersheet.manager.getSheetFormulas(sheetId);
    sheetFormulas.forEach((rowItem, row) => {
      rowItem.forEach((sheetFormula, col) => {
        if (sheetFormula && this.checkSpilledFormula(sheetFormula)) {
          this.createSpillCell(row, col, sheetId, sheetFormula);
        }
      });
    });
  }

  checkIsLockCell(address: SimpleCellAddress) {
    const { col, row, sheet } = address;
    const [cellsLocked] = this.getAddressLockedCells(sheet);

    return cellsLocked.has(`${row}:${col}`);
  }

  clearSpillArea(
    topLeftAddress: SimpleCellAddress,
    rowsLength: number,
    colsLength: number
  ) {
    const { col, row, sheet } = topLeftAddress;
    const [, spillFormulas] = this.getAddressLockedCells(sheet);

    for (let i = 0; i < rowsLength; i++) {
      for (let j = 0; j < colsLength; j++) {
        const address = `${row + i}:${col + j}`;

        if (spillFormulas.has(address)) {
          this.removeSpillAndDependents({ row: row + i, col: col + j, sheet });
        }
      }
    }
  }

  removeSpillAndDependents(spillAddress: SimpleCellAddress) {
    const { row, col, sheet } = spillAddress;
    const address = `${row}:${col}`;
    const [cellsLocked, spillFormulas] = this.getAddressLockedCells(sheet);

    const lockedCells = spillFormulas.get(address);
    lockedCells?.forEach(val => cellsLocked.delete(val));
    spillFormulas.delete(address);
  }

  checkSpilledFormula(formula?: string) {
    return !!(formula && containsSpillFormula(formula));
  }

  filterSpillCells(cellsUpdated: any[], sheetId: number) {
    const [cellsLocked] = this.getAddressLockedCells(sheetId);

    return cellsUpdated.filter(
      ({ address: { r, c } }) => !cellsLocked.has(`${r}:${c}`)
    );
  }

  viewAllLockCells() {
    Object.entries(this.lockSpillCells).map(([key, values]) =>
      console.log("SheetId: ", key, "Blocked", Array.from(values.values()))
    );
    Object.entries(this.spillAddress).map(([key, values]) =>
      console.log(
        "SheetId: ",
        key,
        "Address spill",
        Array.from(values.values())
      )
    );
  }

  getAddressLockedCells(
    sheetId: number
  ): [Set<string>, Map<string, Set<string>>] {
    const existSheetCells = !!this.lockSpillCells[sheetId];

    if (!existSheetCells) {
      this.lockSpillCells[sheetId] = new Set<string>();
      this.spillAddress[sheetId] = new Map();
    }

    return [this.lockSpillCells[sheetId], this.spillAddress[sheetId]];
  }
}
