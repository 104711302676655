import HyperFormula, { CellValue, DetailedCellError, ExportedCellChange, ExportedChange } from "hyperformula";
import server from "../../controllers/server";
import { getSheetIndexByName } from "../../methods/get";
import { datagridgrowth } from "../getdata";
import { Logger } from "../logger/logger";
import { CONFIG } from "./config";
import Store from "../../store";
import { luckysheetrefreshgrid } from "../refresh";
import {
  containsRussian,
  findMaxRC,
  flattenMatrix,
  getMonitorValue,
  hasCrossRef,
  isInArea,
  hasFormula,
  removeQuotesFromWords,
  wrapRussianSheetsWithQuotes,
} from "../HyperformulaUtils";
import { Area, LSCell, LSCelldataType, LuckysheetWindow } from "./types";
import { convertCellDataToManagerData } from "../HyperformulaUtils";
import { DimensionManager } from "./controllers/DimensionManager";
import { BufferController } from "./controllers/BufferController";
import { SenderController } from "./controllers/SenderController";
import { SpillManager } from "./controllers/SpillManager";

type MutateOptions = {
  isRemoving?: boolean;
};

declare const window: LuckysheetWindow;

export class Hypersheet {
  static instance: Hypersheet;
  manager: HyperFormula;
  dimensionManager: DimensionManager;
  spillManager: SpillManager;
  bufferController: BufferController;
  senderController: SenderController;
  LS_sheets: any;

  constructor() {
    if (Hypersheet.instance) {
      return Hypersheet.instance;
    }

    Hypersheet.instance = this;
    this.dimensionManager = new DimensionManager(this);
    this.spillManager = new SpillManager(this);
    this.bufferController = new BufferController();
    this.senderController = new SenderController(this.bufferController);
    window.computationEngine = this;
    Logger.info("Используется: Hypersheet", "ENGINE");
  }

  buildAllSheets(sheets: any[]) {
    this.LS_sheets = sheets;
    const russianSheets = sheets.filter(sheet => containsRussian(sheet.name)).map(sh => sh.name);

    const preparedData = sheets.reduce((acc, currentSheet) => {
      const { name, celldata } = currentSheet;

      return {
        ...acc,
        [name]: convertCellDataToManagerData(celldata, russianSheets),
      };
    }, {});

    this.manager = HyperFormula.buildFromSheets(preparedData, CONFIG);

    const firstSheet = this.LS_sheets[0];
    const firstSheetCelldata = firstSheet.celldata || [];
    const { maxC, maxR } = findMaxRC(firstSheetCelldata);

    const emptyData = datagridgrowth([], Math.max(maxR, Store.defaultrowNum), Math.max(maxC, Store.defaultcolumnNum));
    Store.setFlowData(emptyData);

    const sheetId = this.manager.getSheetId(firstSheet.name);

    if (sheetId !== undefined) {
      this.spillManager.fillLockSpilledCells(sheetId);
      const monitorMatrix = this.manager.getSheetSerialized(sheetId);
      const valuesMatrix = this.manager.getSheetValues(sheetId);

      const HF_celldataFormat = flattenMatrix(valuesMatrix, monitorMatrix);

      const mergedMap = new Map();

      HF_celldataFormat.forEach(({ r, c, v, m }) => {
        mergedMap.set(`${r}:${c}`, { r, c, v, m: v?.toString() });
      });

      firstSheetCelldata.forEach(item => {
        mergedMap.set(`${item.r}:${item.c}`, {
          ...item,
          v: {
            ...item.v,
            ...mergedMap.get(`${item.r}:${item.c}`),
          },
        });
      });

      const LS_resultCelldata = Array.from(mergedMap.values());
      this.spillManager.viewAllLockCells();
      Store.setFlowData(this.initilializeFlowdata(LS_resultCelldata, Store.flowdata));
    }
    luckysheetrefreshgrid();

    // this.manager.on("valuesUpdated", changedCells => {
    //   const cellsUpdated = this.LS_mutateCelldata(changedCells);
    //   this.bufferController.add(cellsUpdated);
    //   // luckysheetrefreshgrid();
    // });
  }

  private initilializeFlowdata(celldata: LSCelldataType[], emptyData: any[][]) {
    const resultArray = emptyData.map(row => [...row]);

    // Заполняем целевой массив на основе данных из sourceArray
    celldata?.forEach(({ r, c, v: cell }) => {
      if (resultArray[r] && resultArray[r][c] !== undefined) {
        const monitorValue = cell?.v ? getMonitorValue(cell) : null;

        resultArray[r][c] = {
          ...cell,
          ...(monitorValue && { m: monitorValue }),
        };
      }
    });

    return resultArray;
  }

  getCellValue(formulaValue: string, sheetLSIndex: string) {
    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined && hasFormula(formulaValue)) {
      const formulaResult = this.manager.calculateFormula(formulaValue, managerSheetId);
      return formulaResult;
    } else {
      return formulaValue;
    }
  }

  pasteContent(topLeft: { row: number; col: number }, contentData: string | number[][], sheetLSIndex: string) {
    const { row, col } = topLeft;
    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const pasteAddress = { row, col, sheet: managerSheetId };
      const changedCells = this.manager.setCellContents(pasteAddress, contentData);
      const cellsUpdated = this.LS_mutateCelldata(changedCells);

      this.bufferController.add(cellsUpdated);
    }
  }

  updateCell(row: number, col: number, cellLS: LSCell, sheetLSIndex: string) {
    const { v: cellValue, f: formulaRaw } = cellLS || {};
    const russianSheets = this.manager.getSheetNames().filter(containsRussian);

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      formula = wrapRussianSheetsWithQuotes(formula, russianSheets);
    }

    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const value = formula || cellValue;
      const cellAddress = { col, row, sheet: managerSheetId };
      const isLockCell = this.spillManager.checkIsLockCell(cellAddress);
      if (isLockCell) return;

      const changedCells = this.manager.setCellContents(cellAddress, value);
      let cellsUpdated = this.LS_mutateCelldata(changedCells);

      cellsUpdated = this.spillManager.processSpilledFormula(row, col, managerSheetId, cellsUpdated, value);

      cellsUpdated = this.spillManager.filterSpillCells(cellsUpdated, managerSheetId);

      console.warn(cellsUpdated, "cellsUpdated");
      this.bufferController.add(cellsUpdated);
    }
  }

  clearContent(start: { row: number; col: number }, end: { row: number; col: number }, sheetLSIndex: string) {
    const sheetNameRaw = this.LS_getSheetNameByIndex(sheetLSIndex);

    const managerSheetId = this.manager.getSheetId(sheetNameRaw);

    if (managerSheetId !== undefined) {
      const { row: startRow, col: startCol } = start;
      const { row: endRow, col: endCol } = end;
      const rowsLength = endRow - startRow + 1;
      const colsLength = endCol - startCol + 1;

      const clearArea = Array(rowsLength)
        .fill([])
        .map(arr => Array(colsLength).fill(undefined));
      const topLeftAddress = {
        row: startRow,
        col: startCol,
        sheet: managerSheetId,
      };

      const changedCells = this.manager.setCellContents(topLeftAddress, clearArea);
      this.spillManager.clearSpillArea(topLeftAddress, rowsLength, colsLength);

      const cellsUpdated = this.LS_mutateCelldata(changedCells, {
        isRemoving: true,
      });
      this.bufferController.add(cellsUpdated);
      this.spillManager.viewAllLockCells();
    }
  }

  onceGroupUpdate() {
    this.manager.once("valuesUpdated", changedCells => {
      const cellsUpdated = this.LS_mutateCelldata(changedCells);
      this.bufferController.add(cellsUpdated);
    });
  }

  createNewSheet(sheetName: string) {
    this.manager.addSheet(`${sheetName}`);
  }

  changeSheet(LS_sheetIndex: string) {
    const emptyData = datagridgrowth([], 100, 30);
    Store.setFlowData(emptyData);
    luckysheetrefreshgrid();

    const nextSheet = this.LS_sheets.find(sheet => sheet.index === LS_sheetIndex);

    const nextSheetCelldata = nextSheet.celldata;

    const sheetId = this.manager.getSheetId(nextSheet.name);

    // TODO: вынести копипасту в отдельный метод
    if (sheetId !== undefined) {
      this.spillManager.fillLockSpilledCells(sheetId);
      const monitorMatrix = this.manager.getSheetSerialized(sheetId);
      const valuesMatrix = this.manager.getSheetValues(sheetId);

      const HF_celldataFormat = flattenMatrix(valuesMatrix, monitorMatrix);

      const mergedMap = new Map();

      HF_celldataFormat.forEach(({ r, c, v, m }) => {
        mergedMap.set(`${r}:${c}`, { r, c, v, m: v?.toString() });
      });

      nextSheetCelldata.forEach(item => {
        mergedMap.set(`${item.r}:${item.c}`, {
          ...item,
          v: {
            ...item.v,
            ...mergedMap.get(`${item.r}:${item.c}`),
          },
        });
      });

      const LS_resultCelldata = Array.from(mergedMap.values());
      this.spillManager.viewAllLockCells();
      Store.setFlowData(this.initilializeFlowdata(LS_resultCelldata, Store.flowdata));
    }
  }

  renameSheet(oldSheetName: string, newSheetName: string) {
    const sheetId = this.manager.getSheetId(oldSheetName);
    const LS_sheetIndex = this.LS_getSheetIndexByName(newSheetName);

    if (sheetId !== undefined) {
      this.manager.renameSheet(sheetId, `${newSheetName}`);
      this.viewRawSheet(sheetId, newSheetName, `RENAME ${oldSheetName} ->`);
      this.updateDependentFormulas(sheetId, LS_sheetIndex);
    }
  }

  addColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    this.dimensionManager.addColumns(sheetLSIndex, indexCol, countColumns);
  }

  addRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    this.dimensionManager.addRows(sheetLSIndex, indexRow, countRows);
  }

  removeColumns(sheetLSIndex: string, indexCol: number, countColumns: number) {
    this.dimensionManager.removeColumns(sheetLSIndex, indexCol, countColumns);
  }

  removeRows(sheetLSIndex: string, indexRow: number, countRows: number) {
    this.dimensionManager.removeRows(sheetLSIndex, indexRow, countRows);
  }

  public viewAllRawSheets() {
    const allRawSheets = Hypersheet.instance.manager.getAllSheetsSerialized();
    Object.entries(allRawSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA RAW VALUES", listName);
    });
  }

  public viewAllSheetsValues() {
    const allValuesSheets = Hypersheet.instance.manager.getAllSheetsValues();
    Object.entries(allValuesSheets).forEach(([listName, table]) => {
      Logger.table(table, "HYPERFORMULA VALUES", listName);
    });
  }

  private viewRawSheet(sheetId: number, sheetNameRaw: string, action: string) {
    Logger.table(this.manager.getSheetSerialized(sheetId), action, sheetNameRaw);
  }

  LS_getSheetNameByIndex(sheetIndex?: string) {
    return window.luckysheet.sheetmanage.getSheetName(sheetIndex);
  }

  LS_getSheetIndexByName(sheetName: string) {
    return getSheetIndexByName(sheetName);
  }

  /**
   * Обновляет все формулы, которые зависят от текущего листа
   * Также обновляет состояние LS
   * @param HF_sheetId - айди листа, от которого зависят другие листы
   */
  updateDependentFormulas(HF_sheetId: number, LS_sheetIndex: string) {
    const cellsForUpdating: any = [];

    const HF_currentSheetName = this.manager.getSheetName(HF_sheetId);
    const HF_allSheets = this.manager.getSheetNames().filter(sheetName => sheetName !== HF_currentSheetName);

    HF_allSheets.forEach(sheetName => {
      const HF_currentId = this.manager.getSheetId(sheetName);

      if (HF_currentId !== undefined && HF_currentSheetName) {
        const sheetFormulas = this.manager.getSheetFormulas(HF_currentId);
        const currentFile = this.LS_sheets.find(shs => shs.index === LS_sheetIndex);

        const currentLSData = currentFile.data;

        sheetFormulas.forEach((rowItem, row) => {
          rowItem.forEach((updatedFormula, col) => {
            if (currentLSData && updatedFormula && updatedFormula.includes(HF_currentSheetName)) {
              // const address: SimpleCellAddress = {
              //   col,
              //   row,
              //   sheet: HF_currentId,
              // };
              // const cellForMutate: ExportedCellChange = {
              //   address: {
              //     col,
              //     row,
              //     sheet: HF_currentId,
              //   },
              //   sheet: HF_currentId,
              //   col,
              //   row,
              //   value: updatedFormula,
              //   newValue: updatedFormula,
              // };
              // this.LS_mutateCelldata(cc);
              // const currentCell = currentLSData[row][col];
              // currentLSData[row][col] = {
              //   ...currentLSData[row][col],
              //   f: removeQuotesFromWords(updatedFormula, russianSheets),
              // };
              // cellsForUpdating.push({
              //   ...currentLSData[row][col],
              //   f: removeQuotesFromWords(updatedFormula, russianSheets),
              //   address: {
              //     r: row,
              //     c: col,
              //     i: currentSheetIndex,
              //   },
              // });
            }
          });
        });
      }
    });

    if (cellsForUpdating.length > 0) {
      server.saveParam("gv", 0, cellsForUpdating);
    }

    luckysheetrefreshgrid();
  }

  updateStyleCells(attr, cellType, area: Area) {
    const LS_currentSheet = this.LS_sheets.find(shs => shs.index === Store.currentSheetIndex.toString());

    LS_currentSheet.celldata = LS_currentSheet?.celldata ? LS_currentSheet.celldata : [];

    const LS_celldata: any[] = LS_currentSheet?.celldata || [];
    const LS_currentUpdatingCells = LS_celldata.filter(cell => isInArea(cell.r, cell.c, area));

    const LS_styledCells = LS_currentUpdatingCells.map(cell => {
      const { r, c, ...restCell } = cell;

      const monitorValue = restCell?.v ? getMonitorValue(restCell.v, cellType) : null;

      let LS_currentCell = LS_celldata.find(curCell => curCell.r === r && curCell.c === c);
      const LS_currentIndexCell = LS_celldata.findIndex(curCell => curCell.r === r && curCell.c === c);

      const { v: LS_currentValue } = LS_currentCell || {};

      LS_currentCell = {
        ...LS_currentCell,
        v: {
          ...restCell.v,
          ...(monitorValue && { m: monitorValue }),
          [attr]: cellType,
        },
      };
      const isUpdateOperation = LS_currentIndexCell !== -1 && LS_currentValue !== null;

      if (isUpdateOperation) {
        LS_celldata[LS_currentIndexCell] = LS_currentCell;
      } else {
        LS_celldata.push(LS_currentCell);
      }

      return {
        ...LS_currentCell.v,
        address: {
          r,
          c,
          i: Store.currentSheetIndex,
        },
      };
    });

    this.bufferController.add(LS_styledCells);
  }

  LS_mutateCelldata(HF_cells: ExportedChange[], options?: MutateOptions) {
    const { isRemoving } = options || {};
    const russianSheets = this.manager.getSheetNames().filter(containsRussian);
    const cellsUpdated: any[] = [];

    HF_cells.forEach(HF_cell => {
      if (HF_cell instanceof ExportedCellChange) {
        const { address: HF_address, newValue } = HF_cell;
        const { row: HF_row, col: HF_col } = HF_address;

        // TODO: Убрать пустое название
        const HF_sheetName = this.manager.getSheetName(HF_address.sheet) || "";
        const LS_sheetIndex = this.LS_getSheetIndexByName(HF_sheetName);

        const LS_currentSheet = this.LS_sheets.find(shs => shs.index === LS_sheetIndex);
        LS_currentSheet.celldata = LS_currentSheet?.celldata ? LS_currentSheet.celldata : [];
        const LS_celldata: LSCelldataType[] = LS_currentSheet?.celldata || [];
        let LS_currentCell: LSCelldataType | undefined = LS_celldata.find(
          curCell => curCell.r === HF_row && curCell.c === HF_col
        );
        const LS_currentIndexCell = LS_celldata.findIndex(curCell => curCell.r === HF_row && curCell.c === HF_col);
        const isUpdateOperation = LS_currentIndexCell !== -1;

        const monitorDefaultValue = newValue !== null ? newValue.toString() : "";
        const isErrorValue = newValue instanceof DetailedCellError;

        const monitorConvertedValue = LS_currentCell
          ? getMonitorValue({
              ...LS_currentCell?.v,
              m: monitorDefaultValue,
              v: newValue,
            })
          : null;
        const isCurrentSheet = Store.currentSheetIndex.toString() === LS_sheetIndex;
        const isExistFormula = this.manager.doesCellHaveFormula(HF_address);
        const HF_formula = this.manager.getCellFormula(HF_address);

        LS_currentCell = {
          c: HF_col,
          r: HF_row,
          v: {
            ...LS_currentCell?.v,
            ...(isExistFormula &&
              HF_formula && {
                f: removeQuotesFromWords(HF_formula, russianSheets),
              }),
            m: monitorConvertedValue ? monitorConvertedValue : monitorDefaultValue,
            v: isErrorValue ? newValue.value : newValue,
            ct: LS_currentCell?.v?.ct ? LS_currentCell?.v?.ct : { fa: "General", t: "g" },
          },
        };

        if (isRemoving) {
          LS_currentCell = {
            r: HF_row,
            c: HF_col,
            v: null,
          };
        }

        if (isCurrentSheet && Store.flowdata[HF_row]) {
          Store.flowdata[HF_row][HF_col] =
            HF_cell.newValue === null
              ? null
              : {
                  ...LS_currentCell.v,
                  r: HF_row,
                  c: HF_col,
                };
        }

        cellsUpdated.push({
          ...LS_currentCell.v,
          address: {
            r: HF_row,
            c: HF_col,
            i: LS_sheetIndex,
          },
        });

        if (isUpdateOperation) {
          LS_celldata[LS_currentIndexCell] = LS_currentCell;
        } else {
          LS_celldata.push(LS_currentCell);
        }
      }
    });

    return cellsUpdated;
  }
}
