/**
 * Transforms passed name to different representaions
 *
 * @param {string} name
 * @returns {object} contains 3 keys, name - passed string, id - id of html element, class - class of html element
 */
const transformName = name => {
  return {
    name,
    id: `#${name}`,
    class: `.${name}`,
  };
};

/**
 * Contains all html ids of Luckysheet elements
 */
export const LuckysheetIds = {
  formulaHelpContainer: transformName("luckysheet-formula-help-c"),
  formulaHelpClose: transformName("luckysheet-formula-help-close"),
  formulaHelpCollapse: transformName("luckysheet-formula-help-collapse"),
  formulaHelpContent: transformName("luckysheet-formula-help-content"),
  modalDialogMask: transformName("luckysheet-modal-dialog-mask"),
  popover: transformName("luckysheetpopover"),
  popoverButton: transformName("luckysheetpopover-btn"),
  tooltipUp: transformName("luckysheet-tooltip-up"),
  info: transformName("luckysheet-info"),
  confirm: transformName("luckysheet-confirm"),
  iframeReportImg: transformName("IframeReportImg"),
  scrollbarX: transformName("luckysheet-scrollbar-x"),
  scrollbarY: transformName("luckysheet-scrollbar-y"),
  cellMain: transformName("luckysheet-cell-main"),
  sheetContainerC: transformName("luckysheet-sheet-container-c"),
  gridWindow1: transformName("luckysheet-grid-window-1"),
  toolbarFormulaHelp: transformName("luckysheet-formulaHelp-btn-title"),
};
