import { CellValue, RawCellContent } from "hyperformula";
import { SPILL_FORMULAS } from "../Hypersheet/config";
import { Area, LSCelldataType, UpdateInput } from "../Hypersheet/types";
import { update } from "../../global/format";

export function convertCellDataToManagerData(celldata, russianSheets: string[]) {
  let newValuesArrs: any[][] = [[]];

  celldata?.forEach((cell: LSCelldataType) => {
    const { r: row, c: column, v } = cell || {};
    const { v: originalCellValue, f: formulaRaw } = v || {};

    let formula = formulaRaw;
    if (formula && hasCrossRef(formula)) {
      formula = wrapRussianSheetsWithQuotes(formula, russianSheets);
    }

    if (!newValuesArrs[row]) {
      newValuesArrs[row] = [];
    }
    newValuesArrs[row][column] = formula ? formula : originalCellValue || undefined;
  });

  const rowsLength = newValuesArrs.length;

  for (let ii = 0; ii < rowsLength; ii++) {
    if (!newValuesArrs[ii]) {
      newValuesArrs[ii] = new Array(rowsLength).fill(undefined);
    }
  }

  return newValuesArrs;
}

export function containsRussian(name) {
  return /[А-Яа-яЁё]/.test(name);
}

export function removeSingleQuotes(input: string) {
  return input.replace(/'/g, "");
}

export function hasCrossRef(input: string) {
  return input.includes("!");
}

// TODO: Стоит дописать доп. логику по удалению кавычек перед их добавление, чтобы не было дубликатов
export function wrapRussianSheetsWithQuotes(inputString: string, russianSheets: string[]) {
  if (russianSheets.length === 0) {
    return inputString;
  }
  // Создаем регулярное выражение для поиска всех слов из массива без учета границ слов
  const regex = new RegExp(`(?<!')(${russianSheets.join("|")})(?!')`, "g");

  // Заменяем найденные вхождения, оборачивая их в одинарные кавычки
  return inputString.replace(regex, "'$1'");
}

export function removeQuotesFromWords(inputString: string, words: string[]): string {
  if (words.length === 0) {
    return inputString;
  }
  // Создаем регулярное выражение, чтобы находить слова, обернутые в кавычки
  const regex = new RegExp(`'(${words.join("|")})'`, "g");

  // Заменяем найденные слова без кавычек
  return inputString.replace(regex, "$1");
}

/**
 * Проверяет наличие слова "TRANSPOSE" в строке (независимо от регистра)
 * @param input - Строка, в которой выполняется поиск
 * @returns boolean - true, если строка содержит "TRANSPOSE", иначе false
 */
export function containsSpillFormula(input?: string | number): boolean {
  if (typeof input === "number") return false;
  return SPILL_FORMULAS.some(spillFormula => input?.toUpperCase().includes(spillFormula));
}

export function findMaxRC(data) {
  let maxR = 0;
  let maxC = 0;

  if (!data || data.length === 0) {
    return {
      maxC: 0,
      maxR: 0,
    };
  }

  data.forEach(({ r, c }) => {
    if (r > maxR) maxR = r;
    if (c > maxC) maxC = c;
  });

  return { maxR, maxC };
}

export function flattenMatrix(valueMatrix: CellValue[][], monitorMatrix: RawCellContent[][]) {
  return valueMatrix.flatMap((row, r) => row.map((value, c) => ({ r, c, m: monitorMatrix[r][c], v: value })));
}

export function filterUniqueAddresses(data: any[]) {
  const seen = new Set();

  return data.filter(({ address }) => {
    const key = `${address.r},${address.c},${address.i}`;
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });
}

export function isInArea(row: number, col: number, area: Area): boolean {
  const { colEnd, colStart, rowEnd, rowStart } = area;

  return row <= rowEnd && row >= rowStart && col >= colStart && col <= colEnd;
}

function isObject(value) {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

export function getMonitorValue(cell: LSCelldataType["v"], newFormat?) {
  const cellValue = cell ? cell.v : null;

  const format = newFormat || cell?.ct;

  if (isObject(cellValue)) {
    return cellValue?.["value"] || "";
  }

  if (cellValue && format) {
    return update(format?.fa, cellValue);
  }

  return cell?.v;
}

export function hasFormula(value: string) {
  return typeof value === "string" && value.startsWith("=");
}

export function printExcelLikeTable(matrix: any[][]): void {
  try {
    const table: { [key: string]: any }[] = [];

    // Количество строк и столбцов в массиве
    const rows = matrix?.length;

    let maxColumns = matrix[0].length;
    for (let r = 0; r < rows; r++) {
      if (matrix[r].length > maxColumns) {
        maxColumns = matrix[r].length;
      }
    }

    // Генерация таблицы с буквами как заголовками колонок
    for (let row = 0; row < rows; row++) {
      const rowData: { [key: string]: any } = { "#": row + 1 }; // Нумерация строк

      for (let col = 0; col < maxColumns; col++) {
        const columnLetter = String.fromCharCode(65 + col); // A, B, C...
        rowData[columnLetter] = matrix[row][col] || undefined; // Значение из двумерного массива
      }

      table.push(rowData);
    }

    console.table(table);
  } catch (error) {
    console.error("Ошибка в создании матрицы");
  }
}

export function headlessUpdateFromInputs(inputData: UpdateInput) {
  Object.entries(inputData).forEach(([sheetId, matrix]) => {
    console.groupCollapsed(sheetId);
    printExcelLikeTable(matrix);
    console.groupEnd();
  });
}
