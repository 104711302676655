import HyperFormula from "hyperformula";
import sheetmanage from "../../controllers/sheetmanage";
import { transliterateIfNeeded } from "./utils";
import { Logger } from "./logger";
import { CONFIG } from "./config";

interface LuckysheetWindow extends Window {
  luckysheet: {
    sheetmanage: typeof sheetmanage;
    formulaManagerDevMode: boolean;
  };
}

type LSCell = {
  m?: string;
  v?: string;
  ct?: { fa: string; t: string };
  f?: string;
};

declare const window: LuckysheetWindow;

export class FormulaManager {
  static instance: FormulaManager;
  manager: HyperFormula;

  constructor() {
    if (FormulaManager.instance) {
      return FormulaManager.instance;
    }

    FormulaManager.instance = this;
  }

  public initManager(formulaEngine: string) {
    this.manager = HyperFormula.buildEmpty(CONFIG);
  }

  setSheetData(celldata, sheetNameRaw?: string) {
    const sheetName = transliterateIfNeeded(sheetNameRaw);

    const currentSheetName = sheetName || this.getSheetLS();
    const sheetId = this.manager.getSheetId(currentSheetName);

    const convertedData = this.convertCellDataToManagerData(celldata);

    if (sheetId === undefined && sheetName) {
      this.createSheetData(convertedData, sheetName);
      return;
    }

    if (sheetId !== undefined) {
      this.updateSheetData(convertedData, sheetId);
      return;
    }

    throw new Error(
      `SheetName must be specified in the parameters, since the HyperFormula sheet does not exist.`
    );
  }

  changeSheet(celldata: LSCell[], isLoad?: boolean, sheetNameRaw?: string) {
    if (isLoad) return;
    const sheetName = transliterateIfNeeded(sheetNameRaw);

    this.setSheetData(celldata, sheetName);
  }

  buildAllSheets(sheets: any[]) {
    const preparedData = sheets.reduce((acc, currentSheet) => {
      const { name, celldata } = currentSheet;
      const sheetName = transliterateIfNeeded(name);

      return {
        ...acc,
        [sheetName]: this.convertCellDataToManagerData(celldata),
      };
    }, {});
    this.manager = HyperFormula.buildFromSheets(preparedData, CONFIG);
  }

  getCellValue(row: number, col: number, sheetLSIndex?: string) {
    const sheetNameRaw = this.getSheetLS(sheetLSIndex);
    const sheetName = transliterateIfNeeded(sheetNameRaw);

    const managerSheetId = this.manager.getSheetId(sheetName);

    if (managerSheetId !== undefined) {
      const cellAddress = { col, row, sheet: managerSheetId };
      const val = this.manager.getCellValue(cellAddress);
      return val;
    }
  }

  cellHaveFormula(row: number, col: number) {
    const sheetNameRaw = this.getSheetLS();
    const sheetName = transliterateIfNeeded(sheetNameRaw);
    const managerSheetId = this.manager.getSheetId(sheetName);

    if (managerSheetId) {
      const cellAddress = { col, row, sheet: managerSheetId };
      return this.manager.doesCellHaveFormula(cellAddress);
    }
  }

  updateCell(row: number, col: number, cell: LSCell, sheetLSIndex?: string) {
    const { v: cellValue, f: formulaRaw } = cell || {};
    const formula = transliterateIfNeeded(formulaRaw);

    const sheetNameRaw = this.getSheetLS(sheetLSIndex);
    const sheetName = transliterateIfNeeded(sheetNameRaw);

    const managerSheetId = this.manager.getSheetId(sheetName);

    if (managerSheetId !== undefined) {
      const cell = formula || cellValue;
      const cellAddress = { col, row, sheet: managerSheetId };
      this.manager.setCellContents(cellAddress, cell);

      const hasFormula = this.manager.doesCellHaveFormula(cellAddress);
      const formulaCell = this.manager.getCellFormula(cellAddress);

      Logger.info(
        `[${row}, ${col}]: ${hasFormula ? formulaCell : ""} ${cellValue}`,
        `UPDATE CELL IN ${sheetNameRaw}`
      );
    }
  }

  updateSheetData(data: string[][], sheetId: number) {
    this.manager.setSheetContent(sheetId, data);
  }

  createSheetData(data: string[][], sheetNameRaw: string) {
    const sheetName = transliterateIfNeeded(sheetNameRaw);

    this.manager.addSheet(sheetName);

    const sheetId = this.manager.getSheetId(sheetName);

    if (sheetId !== undefined) {
      this.manager.setSheetContent(sheetId, data);
      this.viewRawSheet(sheetId, sheetName, "CREATED");
    }
  }

  renameSheet(oldSheetName: string, newSheetName: string) {
    const sheetName = transliterateIfNeeded(newSheetName);

    const sheetId = this.manager.getSheetId(oldSheetName);

    if (sheetId) {
      this.manager.renameSheet(sheetId, sheetName);
      this.viewRawSheet(sheetId, sheetName, `RENAME ${oldSheetName} ->`);
    }
  }

  static viewAllRawSheets() {
    console.info(this.instance.manager.getAllSheetsSerialized());
  }

  private viewRawSheet(sheetId: number, sheetNameRaw: string, action: string) {
    const sheetName = transliterateIfNeeded(sheetNameRaw);

    Logger.table(this.manager.getSheetSerialized(sheetId), action, sheetName);
  }

  private getSheetLS(sheetIndex?: string) {
    return window.luckysheet.sheetmanage.getSheetName(sheetIndex);
  }

  private convertCellDataToManagerData(celldata) {
    let newValuesArrs: string[][] = [[]];
    celldata?.forEach((cell: any) => {
      const { r: row, c: column, v } = cell || {};
      const { v: originalValue, f: formula } = v || {};

      if (!newValuesArrs[row]) {
        newValuesArrs[row] = [];
      }
      newValuesArrs[row][column] = formula
        ? formula
        : originalValue?.toString() || "";
    });

    const rowsLength = newValuesArrs.length;

    for (let ii = 0; ii < rowsLength; ii++) {
      if (!newValuesArrs[ii]) {
        newValuesArrs[ii] = new Array(rowsLength).fill(null);
      }
    }

    return newValuesArrs;
  }
}
